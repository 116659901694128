<template>
  <div>
    <div class="code-foot">
      <h1>山西三二金典图书有限公司</h1>
      <p>晋ICP备2023016254号</p>
      <p class="police">
        <img
          src="https://c.cdn.quyixian.com/front/icon-police.png"
          alt=""
        />晋公网安备 14010502051298号
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "mfooter",
  props: [],
  data() {
    return {};
  },
};
</script>

<style scoped></style>
