<template>
  <div class="box-bg1">
    <div class="home">
      <div class="home-box"></div>
      <div class="home-title">— 官方防伪查询 —</div>
      <van-form class="home-form" @submit="onSubmit">
        <div class="pattern">
          <p>请输入验证码</p>
          <div class="help" @click="helpShow = true">
            <img src="https://c.cdn.quyixian.com/front/help.png" alt="" />
          </div>
        </div>
        <template v-if="codeShow">
          <van-field
            type="number"
            v-model="code"
            placeholder="请输入二维码下方的身份码"
            maxlength="13"
            :rules="[{ required: true }]"
          />
        </template>
        <van-field
          type="number"
          v-model="verify"
          placeholder="请输入验证码"
          minlength="4"
          :rules="[{ required: true }]"
        />
        <template v-if="loading">
          <van-button block type="info" :icon="subIcon" native-type="submit"
            >{{ subText }}
          </van-button>
        </template>
        <template v-else>
          <van-button
            loading
            block
            type="info"
            loading-type="spinner"
            loading-text="定位中..."
          />
        </template>
      </van-form>
      <div class="code-follow">
        <div class="code-follow-public1">
          <img src="https://c.cdn.quyixian.com/front/32wx.jpg" alt="" />
          <p>3年高考2年模拟</p>
        </div>
        <P>长按识别二维码关注公众号</P>
        <P>获取更多图书增值服务</P>
      </div>
      <mFooter />
      <van-popup closeable v-model="helpShow">
        <div class="code-help">
          <img src="https://c.cdn.quyixian.com/front/code-help.png" alt="" />
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import mFooter from "@/components/mfooter.vue";
import qs from "qs";
import { Toast, Dialog } from "vant";

export default {
  name: "indexHome",
  components: { mFooter },
  data() {
    return {
      loading: false,
      code: this.$route.query.id, //3282308232160
      codeShow: this.$route.query.id ? false : true, //是否有值，有值扫码, 无值公众号 是否显示输入框
      tid: this.$route.query.tId, //暂时无用
      verify: "", //验证码
      source: this.$route.query.source == null ? 1 : this.$route.query.source, //来源 1H5 2公众号 3手机官网 4PC官网
      bookInfo: null, //图书信息
      codeInfo: null, //码的信息
      codeType: "", //类型 1多次 2首次 3未采集或未入库出库信息 4没查到
      helpShow: false, //帮助弹出层
      subIcon: "https://c.cdn.quyixian.com/front/click1.png",
      subText: "验证",
      isWx: this.$wechat.isWeixin(), //是否再微信环境下
      province: "",
      ip: "",
    };
  },
  mounted() {
    //拿IP
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        this.ip = ip;
        console.log(this.ip);
      });

    //是否微信环境
    if (this.isWx) {
      this.$wechat.getLocation().then((res) => {
        let params = {
          location: res.latitude + "," + res.longitude,
        };
        //console.log(res)
        this.$api.code.getWxAddres(params).then((r) => {
          let { Tag, Data } = r;
          if (Tag == 1 && Data.message == "query ok") {
            let { result } = Data;
            console.log(result);
            this.savePosition(
              result.address_component.province,
              result.address_component.city,
              result.address_component.district,
              result.address,
              res.latitude,
              res.longitude
            );
          } else {
            console.log("定位失败");
            Dialog.alert({
              title: "定位失败",
              message: "未获取到您的位置信息！请刷新",
              confirmButtonText: "刷新",
            }).then(() => {
              //刷新页面
              window.location.reload();
            });
          }
        });
      });
    } else {
      this.getLocation();
    }
  },
  methods: {
    //查询
    onSubmit(e) {
      if (!isNaN(this.verify) && this.verify.length < 4) {
        Toast("请输入正确的验证码");
        return false;
      }
      if (this.province == "") {
        Dialog.alert({
          title: "拒绝查询",
          message: "未获取到您的定位！",
          confirmButtonText: "刷新",
        }).then(() => {
          location.reload();
        });
        return false;
      }
      let params = {
        Code1: this.code,
        VerifyCode: this.verify,
      };
      this.$api.code.GetCode(qs.stringify(params)).then((res) => {
        let { Data, Tag, Message } = res;
        if (Tag == 1) {
          let { bookJson, codeJson, codeType } = Data;
          //取图书信息
          this.bookInfo = bookJson;
          //取类型
          this.codeType = codeType;
          //取码信息
          this.codeInfo = codeJson;

          let timestamp = new Date().getTime();

          this.$router.push({
            name: "indexResult",
            params: {
              code: this.code,
              verify: this.verify,
              source: this.source,
              bookInfo: this.bookInfo,
              codeType: this.codeType,
              codeInfo: this.codeInfo,
              ip: this.ip,
            },
            query: {
              time: timestamp,
            },
          });
        } else {
          this.$dialog.alert({
            title: "错误",
            message: Message,
          });
        }
      });
    },
    //注册腾讯获取KEY
    getLocation() {
      let geolocation = new window.qq.maps.Geolocation(
        "SPABZ-Z2W3T-LVBX5-LTVJR-SFUMV-DXF6K",
        "mycode",
        [{ timeout: 10, failTipFlag: true }]
      );
      //getLocation(成功回调,错误回调)
      geolocation.getLocation(this.showPosition, this.errorPosition);
    },
    //获取位置成功
    showPosition(position) {
      console.log("定位成功");
      console.log(position);
      this.savePosition(
        position.province,
        position.city,
        position.district,
        position.addr,
        position.lat,
        position.lng
      );
    },
    //获取位置出错
    errorPosition() {
      console.log("定位失败");
      Dialog.alert({
        title: "定位失败",
        message: "未获取到您的位置信息！请刷新",
        confirmButtonText: "刷新",
      }).then(() => {
        //刷新页面
        window.location.reload();
      });
    },
    //storage保存位置信息
    savePosition(pro, city, dis, add, lat, lng) {
      this.loading = true;
      this.province = pro;
      let info = {
        province: pro,
        city: city,
        district: dis,
        address: add,
        lat: lat,
        lng: lng,
      };
      sessionStorage.setItem("addr", JSON.stringify(info));
    },
  },
};
</script>

<style lang="scss">
.home-form {
  position: relative;
}

.pattern {
  position: absolute;
  left: 50%;
  top: -0.3rem;
  transform: translate(-50%, 0);
  width: 4rem;
  height: 0.8rem;
  background: url("@/assets/pattern.png") no-repeat center;
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.32rem;
  color: #333;

  p {
    margin-right: 0.2rem;
  }

  .help {
    height: 0.8rem;
    display: flex;
    align-items: center;
  }

  img {
    width: 0.4rem;
  }

  //img{
  //  width: 4rem;
  //  height: .8rem;
  //}
  //&:before,&::after{
  //  content: ' ';
  //  position: absolute;
  //  background: #e95728;
  //  display: block;;
  //  width: .8rem;
  //  height: .8rem;
  //  border-radius: 100%;
  //  z-index: -1;
  //}
  //&:before{
  //  left: 0;
  //}
  //&::after{
  //  right: 0;
  //}
}
</style>
