<template>
  <div class="box-bg">
    <div class="result">
      <div class="result-box"></div>
      <div class="result-main">
        <template v-if="codeType == 2 || codeType == 1 || codeType == 3">
          <div class="result-main-book" v-if="bookInfo">
            <template v-if="bookInfo.NickName">
              <h3 class="title">{{ bookInfo.NickName }}</h3>
            </template>
            <template v-else>
              <h3 class="title">{{ bookInfo.Name }}</h3>
            </template>
            <div class="content">
              <div class="info">
                <p><span>品　牌：</span><em>曲一线</em></p>
                <p>
                  <span>版　本：</span><em>{{ bookInfo.Banben }}</em>
                </p>
                <p>
                  <span>书　号：</span><em>{{ bookInfo.BookNumber }}</em>
                </p>
                <p>
                  <span>开　本：</span><em>{{ bookInfo.Folio }}</em>
                </p>
                <p>
                  <span>定　价：</span
                  ><em v-if="bookInfo.Pricing">{{ bookInfo.Pricing }}元</em>
                </p>
                <p class="flexStart">
                  <span>出版社：</span><em v-html="bookInfo.Publishing"></em>
                </p>
              </div>
              <div class="cover">
                <img :src="bookInfo.Cover" alt="" />
                <!--<img src="https://c.cdn.quyixian.com/front/bookCover.png" alt="">-->
              </div>
            </div>
          </div>
          <div class="result-main-verify">
            <h4>验证结果</h4>
            <template v-if="codeType == 1 || codeCount == 0">
              <h5>此图书首次被验证</h5>
              <div class="info">
                <p>
                  首次查询时间：{{
                    $moment(currentTime).format("YYYY年MM月DD日HH时mm分")
                  }}
                </p>
                <p>首次查询地址：{{ currentAddress }}</p>
              </div>
            </template>
            <template v-else>
              <template v-if="isPirate == 1">
                <div class="pirate">
                  <div class="pirate-mian">
                    <img
                      src="https://c.cdn.quyixian.com/front/pirate.gif"
                      alt=""
                    />
                    <h6>此书为盗版图书</h6>
                  </div>
                  <div v-html="codeInfo.Info"></div>
                </div>
              </template>
              <h5>
                <template v-if="codeCount >= 10">
                  <i>警告：</i>
                </template>
                此图书已被验证{{ codeCount }}次！
              </h5>
              <div class="info">
                <p>
                  首次查询时间：{{
                    $moment(firstTime).format("YYYY年MM月DD日HH时mm分")
                  }}
                </p>
                <p>首次查询地址：{{ firstAddress }}</p>
              </div>
              <template v-if="isPirate == 0 && codeCount >= 10">
                <p>该防伪码查询次数过多，谨防假冒！</p>
              </template>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="result-main-noBook">
            <h3>您所查询的图书可能是盗版图书</h3>
            <p>
              盗版图书可能存在危害身体健康、图书内容有误等情况，无法提供高质量的图书内容。建议您举报盗版，并通过官方渠道购买正版图书。
            </p>
          </div>
          <!-- <div class="result-main-verify">
            <p class="bold">举报盗版</p>
            <p>盗版举报电话：010-87606918</p>
            <p>客服热线：400-898-5353 13311185353（微信同号）</p>
          </div> -->
          <div class="result-pirate" style="margin-top: 0">
            <router-link :to="'/pirate?code=' + code" replace>
              <van-image
                width="375"
                src="https://c.cdn.quyixian.com/front/pirate/pirateindex.png"
              />
            </router-link>
          </div>
        </template>
      </div>
      <template v-if="isPirate == 1">
        <div class="result-pirate">
          <router-link :to="'/pirate?code=' + code" replace>
            <van-image
              width="375"
              src="https://c.cdn.quyixian.com/front/pirate/pirateindex.png"
            />
          </router-link>
        </div>
      </template>
      <div class="result-info">
        <h4>特别说明</h4>
        <div class="rows">
          <P>正版图书验证码才有喷印工艺，触摸二维码会有凹凸不平的感觉</P>
          <img src="https://c.cdn.quyixian.com/front/code2.png" alt="" />
        </div>
        <!-- <div class="rows">
          <P>2、使用激光笔照射图书封底，封底会显示二维码下方的防伪数字</P>
          <img src="https://c.cdn.quyixian.com/front/bluray2.png" alt="" />
        </div> -->
      </div>
      <div class="code-follow">
        <div class="code-follow-public">
          <div class="plm">
            <img
              width="200"
              src="https://c.cdn.quyixian.com/front/32wx.jpg"
              alt=""
            />
            <p style="margin-top: 0">3年高考2年模拟</p>
          </div>
        </div>
        <P>长按识别二维码关注公众号</P>
        <P>获取更多图书增值服务</P>
      </div>
      <mFooter />
    </div>
  </div>
</template>

<script>
import qs from "qs";
import mFooter from "@/components/mfooter";
import { Toast, Dialog } from "vant";

export default {
  name: "indexResult",
  components: { mFooter },
  data() {
    const self = this;
    return {
      loading: false,
      code: this.$route.params.code, //码
      verify: this.$route.params.verify, //验证码
      source: this.$route.params.source, //来源
      bookInfo: this.$route.params.bookInfo, //图书信息
      codeInfo: this.$route.params.codeInfo, //码信息
      codeType:
        this.$route.params.codeType == null ? 4 : this.$route.params.codeType, //类型  1多次 2首次 3未采集或未入库出库信息  4没查到
      ip: this.$route.params.ip,
      map: JSON.parse(sessionStorage.getItem("addr")), //定位信息
      //sohu: returnCitySN, //搜狐定位
      isPirate: 0, //是否提示盗版信息来源后台 默认0关闭
      codeCount: 0, //查询次数
      firstTime: "", //首次时间
      firstAddress: "", //首次地址
      currentAddress: "", //当前地址
      currentTime: new Date(), //当前时间
    };
  },
  mounted() {
    if (this.ip == null || this.ip == "") {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.ip = ip;
        });
    }
    if (this.codeInfo != null) {
      let { FirstTime, FirstAddress, Count, IsPirate, Info } = this.codeInfo;
      this.isPirate = IsPirate;
      this.firstTime = FirstTime;
      this.firstAddress = FirstAddress;
      if (Count && Count > 0) {
        this.codeCount = Count + 1;
      }
    }
    if (this.map.province != null) {
      this.currentAddress =
        this.map.address == null ? this.map.province : this.map.address;
    }

    if (this.bookInfo != null) {
      let pub = this.bookInfo.Publishing;
      if (pub) {
        this.bookInfo.Publishing = pub.replace(/  /, "<br/>");
      }
    }
    //console.log(this.bookInfo)

    //延时保存记录
    let that = this;
    setTimeout(function () {
      if (that.map.address == null || that.map.province == null) {
        Dialog.alert({
          title: "拒绝查询",
          message: "获取位置信息异常！",
          confirmButtonText: "返回",
        }).then(() => {
          that.$router.push({ path: "/", query: { id: that.code } });
        });
        return false;
      } else {
        that.SaveUser();
      }
    }, 1000);
    //console.log(this.$route.params.bookInfo);
    //console.log(this.$route.params.codeInfo);
    //console.log(this.$route.params.codeType);
    //console.log(this.codeType);
  },
  methods: {
    SaveUser() {
      let task = null,
        taskpress = null,
        book = null,
        code2 = null;
      if (this.codeInfo != null) {
        if (this.codeInfo.TaskId) {
          task = this.codeInfo.TaskId;
        }
        if (this.codeInfo.TaskId) {
          taskpress = this.codeInfo.TaskPressId;
        }
        if (this.codeInfo.BookId) {
          book = this.codeInfo.BookId;
        }
        if (this.codeInfo.Code2) {
          code2 = this.codeInfo.Code2;
        }
      }
      let info =
        "province:" +
        this.map.province +
        ",city:" +
        this.map.city +
        ",district:" +
        this.map.district +
        ",lat:" +
        this.map.lat +
        ",lng:" +
        this.map.lng;
      let params = {
        CodeType: this.codeType,
        Code1: this.code,
        VerifyCode: this.verify,
        TaskId: task,
        TaskPressId: taskpress,
        BookId: book,
        Code2: code2,
        Source: this.source,
        //
        IP: this.ip,
        Address: this.map.address,
        Province: this.map.province,
        City: this.map.city,
        District: this.map.district,
        Info: info,
      };
      this.$api.code.SaveUserInfo(qs.stringify(params)).then((res) => {
        let { Data, Tag, Message } = res;
        if (Tag == 1) {
          //console.log(params);
        } else {
          this.$dialog.alert({
            title: "错误",
            message: Message,
          });
        }
      });
    },

    formatStr(str) {
      str = str.replace(/\r\n/gi, "<br/>");
      return str;
    },
  },
};
</script>

<style lang="scss">
.result {
  .loading {
    margin: 0.3rem 0;
  }
}
</style>
