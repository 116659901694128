<template>
  <div class="pirate-index">
    <div class="pirate-index-banner">
      <van-image src="https://c.cdn.quyixian.com/front/pirate/banner1.png" />
    </div>
    <div class="pirate-index-video">
      <div v-show="playShow" class="show" @click="onVideoPlayer">
        <van-image class="video-bg" src="https://c.cdn.quyixian.com/front/pirate/video-bg1.png" />
        <van-image width="60" class="play" src="https://c.cdn.quyixian.com/front/pirate/play.png" />
      </div>
      <div v-show="playHide" class="hide">
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"> </video-player>
      </div>
    </div>
    <div class="pirate-index-box">
      <van-image class="titleImg" width="145" height="40" src="https://c.cdn.quyixian.com/front/pirate/1.png" />
      <div class="content content1">
        <p class="top indent letter">
          盗版书籍作为翻版书，其出版未经原作者或原出版商授权，一般封面<em class="fontColor">色彩鲜艳、字迹混浊、用纸较薄</em>等。盗版图书的印刷往往不符合国家标准，可能含有<em
            class="fontColor"
            >超标的重金属元素</em
          >，长期使用<em class="fontColor">不利于身体健康；字迹浑浊，不利于保护学生的视力。</em>
        </p>
        <span class="sign letter"></span>
      </div>
    </div>
    <div class="pirate-index-box">
      <van-image class="titleImg" width="176" height="40" src="https://c.cdn.quyixian.com/front/pirate/2.png" />
      <div class="content">
        <p class="top mainTitle">【线上购买】</p>
        <p class="indent">
          在线上买到盗版书籍，可第一时间将检验结果发至卖家，向其索赔或协商处理。根据《中华人民共和国消费者权益保护法》第五十五条（经营者提供商品或者服务有欺诈行为的，应当按照消费者的要求增加赔偿其受到的损失，增加赔偿的金额为消费者购买商品的价款或者接受服务的费用的三倍；增加赔偿的金额不足五百元的，为五百元。法律另有规定的，依照其规定。）可向其索五百元赔偿金。
        </p>
        <p>若商家拒不配合或协商无果，可通过以下方式反馈处理：</p>
        <p>① 联系我们：010-87606918（盗版举报热线）</p>
        <p>② 微信小程序搜索“黑猫投诉”进行投诉。</p>
        <p>③ 微信搜索“12315”公众号进行投诉。</p>
        <p>④ 拨打“12315”电话进行投诉处理。</p>
        <p class="top1 mainTitle">【线下购买】</p>
        <p class="indent">
          在线下买到盗版书籍，可自行与书店协商退款，或拨打“12315”和“12318”热线进行投诉。也可主动联系我司（010-87606918）提供购买书店的地址信息，经我司查实，将酌情予以正版图书奖励或现金奖励。
        </p>
        <span class="sign letter"></span>
      </div>
    </div>
    <div class="pirate-index-box">
      <van-image class="titleImg" width="152" height="40" src="https://c.cdn.quyixian.com/front/pirate/3.png" />
      <div class="content">
        <p class="top indent">为保障广大消费者购买我司出版产品的消费权益，即日起将开通“盗版店铺信息收集通道”，您可直接提交举报或协商过程截图，我们将主动和您联系并酌情给予奖励。</p>
        <div class="pirateform">
          <router-link :to="'/pirateform?code=' + code">
            <van-image width="182" height="60" src="https://c.cdn.quyixian.com/front/pirate/5.png" />
          </router-link>
        </div>
        <span class="sign letter"></span>
      </div>
    </div>
    <div class="pirate-index-box">
      <van-image class="titleImg" width="97" height="40" src="https://c.cdn.quyixian.com/front/pirate/4.png" />
      <div class="content">
        <p class="top center">购书请认准正规官方渠道</p>
        <div class="code">
          <van-image width="100" src="https://c.cdn.quyixian.com/front/pirate/pdd.png" />
          <van-image width="100" src="https://c.cdn.quyixian.com/front/pirate/tt.png" />
        </div>
        <span class="sign letter"></span>
      </div>
    </div>
    <div class="pirate-index-footer">
      <p>感谢您对我们产品的支持！</p>
      <p>打击盗版 人人有责！</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pirate',
  data() {
    return {
      code: this.$route.query.code,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: '', //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: 'https://c.cdn.quyixian.com/front/pirate/90.mp4' //url地址
          }
        ],
        poster: 'https://c.cdn.quyixian.com/front/pirate/video-bg.png', //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      },
      playShow: true,
      playHide: false
    }
  },
  mounted() {
    let that = this
    console.log(this.$refs.videoPlayer.player)
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    onPlayer() {
      this.$refs.videoPlayer.player.play()
    },
    onVideoPlayer() {
      this.playHide = true
      this.playShow = false
      this.onPlayer()
    }
  }
}
</script>

<style lang="scss">
.pirate-index {
  height: auto;
  background: #f2c09e;

  &-banner {
    .van-image {
      display: block;
    }
  }

  &-video {
    margin: 0 0.3rem 0.3rem 0.3rem;
    border-radius: 0.3rem;
    padding: 0.2rem;
    background: #fae6d8;

    .show {
      position: relative;
    }

    .video-bg {
      display: block;
    }
    .play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-box {
    background: #fff;
    margin: 0 0.3rem 0.3rem 0.3rem;
    border-radius: 0.3rem;
    padding: 0.1rem;
    font-size: 0.24rem;
    position: relative;

    .titleImg {
      position: absolute;
      left: -0.15rem;
      top: 0.23rem;
    }

    .content1 {
      font-size: 0.28rem;
    }
    .content {
      border: 1px dashed #fddcaa;
      border-radius: 0.3rem;
      padding: 0 0.3rem;

      .mainTitle {
        font-size: 0.28rem;
        position: relative;
        z-index: 999;

        &:after {
          content: ' ';
          height: 4px;
          width: 1.4rem;
          background: #ffc134;
          position: absolute;
          left: 0.13rem;
          bottom: 0.1rem;
        }
      }

      .top {
        margin-top: 1.2rem;
      }
      .top1 {
        margin-top: 0.8rem;
      }

      .center {
        text-align: center;
        font-size: 0.28rem;
      }

      .indent {
        text-indent: 2em;
      }
      .letter {
        letter-spacing: 1px;
      }

      .sign {
        font-size: 0.32rem;
        color: #fdd5a9;
      }

      .fontColor {
        color: #fba72b;
      }

      .pirateform {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.3rem;
      }

      .code {
        display: flex;
        justify-content: center;
        align-items: center;

        .van-image {
          margin: 0.3rem;
        }
      }
    }
  }

  &-footer {
    font-size: 0.36rem;
    color: #fff;
    text-align: center;
    line-height: 1.6;
    padding: 0.3rem 0 0.5rem 0;
    text-shadow: 0 2px 2px #999;
  }
}
</style>
