<template>
  <div class="success">
    <img
      class="success-img"
      src="https://c.cdn.quyixian.com/front/2023/con.png"
      alt=""
    />
    <div class="success-title">信息已完成确认</div>
    <p class="success-content">请关闭此页面</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.success {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &-img {
    width: 2.3rem;
    margin-top: 3rem;
  }
  &-title {
    font-size: 0.36rem;
    line-height: 3;
    color: #ff8450;
  }
  &-content {
    color: #999;
  }
}
</style>
