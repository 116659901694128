<template>
  <div class="address">
    <div class="address-title">确认收货信息</div>
    <div class="address-content">
      <div class="address-top">
        <img
          class="address-book"
          src="https://c.cdn.quyixian.com/front/2023/book.png"
          alt=""
        />
        <div class="address-box">
          <span>批销单号：</span>
          <p>123123</p>
        </div>
      </div>
      <div class="address-bottom">
        <van-button type="primary" block round>确定收货</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "address",
};
</script>

<style lang="scss" scoped>
.address {
  height: 100%;
  background: #5795e0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-title {
    color: #fff;
    font-size: 0.48rem;
    text-align: left;
    margin: 0.6rem 0.3rem 0.3rem 0.3rem;
  }
  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.3rem;
    background: #fff;
    margin: 0.3rem;
    padding: 0.3rem;
    font-size: 0.28rem;
    position: relative;
  }

  &-book {
    position: absolute;
    right: 0.3rem;
    top: -1.46rem;
    width: 2.1rem;
    height: 1.46rem;
  }

  &-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    padding: 0.3rem 0;
    border-bottom: 1px solid #ececec;

    p {
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-height: 1.6;
    }
  }

  .van-button {
    &--primary {
      background: linear-gradient(to bottom, #f4dc4c, #ff8350);
      border: 0;
    }
  }
}
</style>
