<template>
  <div class="luck">
    <div class="luck-bg">
      <div class="luck-bg-box"></div>
      <LuckyWheel
        ref="myLucky"
        width="300px"
        height="300px"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        @start="startCallback"
        @end="endCallback"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "luckDraw",
  data() {
    return {
      blocks: [
        {
          imgs: [
            {
              src: "https://c.cdn.quyixian.com/front/prize/prize-bg3.png",
              top: "0",
              width: "100%",
              height: "100%",
              rotate: true,
            },
          ],
        },
      ],
      prizes: [
        {
          fonts: [{ text: "0", top: "0", fontColor: "transparent" }],
          background: "transparent",
        }, //0-53图书
        {
          fonts: [{ text: "1", top: "0", fontColor: "transparent" }],
          background: "transparent",
        }, //1-现金红包
        {
          fonts: [{ text: "2", top: "0", fontColor: "transparent" }],
          background: "transparent",
        }, //2-53笔袋
        {
          fonts: [{ text: "3", top: "0", fontColor: "transparent" }],
          background: "transparent",
        }, //3-谢谢参与
        {
          fonts: [{ text: "4", top: "0", fontColor: "transparent" }],
          background: "transparent",
        }, //4-积分
        {
          fonts: [{ text: "5", top: "0", fontColor: "transparent" }],
          background: "transparent",
        }, //5-53书包
      ],
      buttons: [
        {
          radius: "30%",
          imgs: [
            {
              src: "https://c.cdn.quyixian.com/front/prize/prize-btn.png",
              width: "100%",
              top: "-125%",
            },
          ],
          fonts: [
            {
              text: "剩余1次",
              fontSize: ".24rem",
              fontColor: "#fff",
              top: "30%",
            },
          ],
        },
      ],
      defaultConfig: {
        offsetDegree: -30,
      },
    };
  },
  mounted() {},
  methods: {
    // 点击抽奖按钮会触发star回调
    startCallback() {
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play();
      // 模拟调用接口异步抽奖
      setTimeout(() => {
        // 假设后端返回的中奖索引是0

        const index = 0;
        // 调用stop停止旋转并传递中奖索引
        this.$refs.myLucky.stop(index);

        this.buttons[0].fonts[0].text = "剩余0次";
      }, 3000);
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log(prize);
    },
  },
};
</script>
<style lang="scss">
.luck {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
  background: #ff1d4a;

  &-bg {
    width: 100%;
    background: #ff1d4a url("https://c.cdn.quyixian.com/front/prize/bg1.jpg")
      no-repeat top;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;

    &-box {
      height: 3.8rem;
    }
  }
}
</style>
