import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Result from "../views/Result";
import Pirate from "../views/Pirate";
import PirateForm from "../views/PirateForm";
import cont from "../views/cont";
//
import indexHome from "../views/index/Home";
import indexResult from "../views/index/Result";
//
import pcindex from "../views/pc-index/index.vue";
//
import newhome from "../views/2023/newHome";
import newresult from "../views/2023/newresult";
import luckDraw from "../views/2023/luckDraw";
import success from "../views/2023/success";
import address from "../views/2023/address";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "pcindex",
    component: pcindex,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/result",
    name: "Result",
    component: Result,
  },
  {
    path: "/PirateForm",
    name: "PirateForm",
    component: PirateForm,
  },
  {
    path: "/pirate",
    name: "Pirate",
    component: Pirate,
  },
  {
    path: "/indexHome",
    name: "indexHome",
    component: indexHome,
  },
  {
    path: "/indexResult",
    name: "indexResult",
    component: indexResult,
  },
  {
    path: "/cont",
    name: "cont",
    component: cont,
  },
  {
    path: "/newhome",
    name: "newhome",
    component: newhome,
  },
  {
    path: "/newresult",
    name: "newresult",
    component: newresult,
  },
  {
    path: "/luckDraw",
    name: "luckDraw",
    component: luckDraw,
  },
  {
    path: "/success",
    name: "success",
    component: success,
  },
  {
    path: "/address",
    name: "address",
    component: address,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(tp, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
