import Vue from "vue";
import api from "./api/install";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import "vant/lib/index.css";
import moment from "moment";
import tool from "./assets/scripts/tool";
import wechat from "./assets/scripts/wechat";
import VueVideoPlayer from "vue-video-player";
import VueLuckyCanvas from "@lucky-canvas/vue";
require("../node_modules/video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
import animate from "animate.css";
import "swiper/css/swiper.css";
import VueAwesomeSwiper from "vue-awesome-swiper";

Vue.prototype.tool = tool;
Vue.prototype.$wechat = wechat;
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

Vue.use(api).use(vant).use(VueAwesomeSwiper);
//.use(VueVideoPlayer).use(VueLuckyCanvas).use(animate)
new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
