<template>
  <div class="container">
    <div class="newresult">
      <div class="newresult-book">
        <img
          class="newresult-book-title"
          src="https://c.cdn.quyixian.com/front/2023/bookinfo.jpg"
          alt=""
        />
        <div class="newresult-book-box">
          <div class="newresult-book-left">
            <img src="https://c.cdn.quyixian.com/front/2023/b.png" alt="" />
          </div>
          <div class="newresult-book-right">
            <div class="newresult-book-content">
              <h1 class="bookName">
                2021秋五三天天练小学语文 一年级上册RJ人教部编版
              </h1>
              <div class="bookContent">
                <p><span>品牌：</span><em>曲一线</em></p>
                <p><span>版本：</span><em>人教版</em></p>
                <p><span>书号：</span><em>98123123123123</em></p>
                <p><span>开本：</span><em>16开</em></p>
                <p><span>定价：</span><em>24.00元</em></p>
                <p><span>出版社：</span><em>首都师范大学出版社</em></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="newresult-fruit newresult-public">
        <img
          class="newresult-public-title"
          src="https://c.cdn.quyixian.com/front/2023/result.jpg"
          alt=""
        />
        <!-- <h1 class="title co">此图书首次被验证！</h1> -->
        <!-- <h1 class="title">此图书已被验证3次！</h1> -->
        <!-- <h1 class="title co">警告：此图书已被验证5次！</h1> -->
        <h1 class="title co">警告：此书为盗版图书！</h1>
        <div class="steal animate__animated animate__heartBeat">
          <img
            class=""
            src="https://c.cdn.quyixian.com/front/2023/dao.png"
            alt=""
          />
        </div>
        <div class="content">
          <p>请与我公司客服联系（电话：13812345678）</p>
          <p>凡提供相关线索一经查实，我公司将给予正版图书及现金奖励！</p>
          <p>首次查询时间：2021-03-05 12:40</p>
          <p>首次查询地址：北京北京市大兴区北京曲一线图书策 划有限公司</p>
          <p class="co">该防伪码查询次数过多，谨防假冒！</p>
        </div>
        <!-- <router-link class="link-lucky" :to="{ name: '123' }"
          >去抽奖</router-link
        > -->
      </div>

      <div class="newresult-steal">
        <img src="https://c.cdn.quyixian.com/front/2023/steal2.png" alt="" />
      </div>

      <div class="newresult-explain newresult-public">
        <img
          class="newresult-public-title"
          src="https://c.cdn.quyixian.com/front/2023/explain.jpg"
          alt=""
        />
        <div class="newresult-explain-box">
          <img src="https://c.cdn.quyixian.com/front/2023/code.png" alt="" />
          <h6>喷印工艺</h6>
          <p>
            正版图书的验证二 维码会采用喷印工 艺，用手触摸二维 码会有凹凸不平的
            感觉。
          </p>
        </div>
        <div class="newresult-explain-box newresult-explain-line">
          <img src="https://c.cdn.quyixian.com/front/2023/blue.png" alt="" />
          <h6>隐藏防伪</h6>
          <p>
            使用激光笔照射正 版图书封面，封面 上会显示和封底二 维码下方的身份码
            相同的数字。
          </p>
        </div>
        <div class="newresult-explain-box">
          <img src="https://c.cdn.quyixian.com/front/2023/one.png" alt="" />
          <h6>一书一码</h6>
          <p>
            正版图书的验证码 为一书一码，且每 本图书均有唯一的 13位身份码及4位
            验证码。
          </p>
        </div>
      </div>

      <div class="newresult-vs">
        <router-link :to="{ name: 'cont' }"
          ><img src="https://c.cdn.quyixian.com/front/2023/banner.png" alt=""
        /></router-link>
      </div>
    </div>
    <div class="qyx">北京曲一线图书策划有限公司</div>
  </div>
</template>

<script>
export default {
  name: "newresult",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.newresult {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 0 0.3rem;
  &-public {
    border: 1px solid #5795e0;
    border-radius: 0.3rem;
    position: relative;
    margin: 0.6rem 0;

    &-title {
      position: absolute;
      left: 50%;
      top: -0.37rem;
      width: 2.94rem;
      height: 0.74rem;
      transform: translateX(-50%);
    }
  }
  &-book {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0.3rem 0;

    &-title {
      width: 2.94rem;
      height: 0.74rem;
      margin-bottom: 0.3rem;
    }
    &-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &-left {
      max-width: 2.7rem;
      max-height: 4.02rem;
      img {
        width: 100%;
      }
    }
    &-right {
      flex: 1;
      width: 4.34rem;
      height: 4.28rem;
      background: url("https://c.cdn.quyixian.com/front/2023/bookinfo0bg1.png")
        no-repeat;
      background-size: 100%, 100%;
    }

    &-content {
      margin: 0.3rem;
      padding-left: 0.1rem;

      .bookName {
        font-size: 0.28rem;
        font-weight: 500;
        line-height: 1.6;
        margin: 0.15rem 0;
      }
      .bookContent {
        font-size: 0.2rem;

        p {
          line-height: 1.8;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        span {
          width: 1rem;
          text-align: right;
        }
      }
    }
  }
  &-fruit {
    padding: 0.6rem 0.3rem;
    .title {
      text-align: center;
      font-size: 0.4rem;
      margin: 0.15rem;
    }
    .content {
      font-size: 0.24rem;
      line-height: 2.4;
    }
    .co {
      color: #ff8450;
    }
    .steal {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.3rem 0;
      width: 100%;

      img {
        width: 2.68rem;
        height: 2.58rem;
      }
    }
    .link-lucky {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to bottom, #f4dc4c, #ff8350);
      color: #fff;
      border-radius: 0.15rem;
      height: 0.8rem;
      margin-top: 0.3rem;
    }
  }
  &-steal {
    display: flex;
    margin-bottom: 0.3rem;
    box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.1);
    img {
      width: 100%;
    }
  }
  &-explain {
    display: flex;
    justify-content: space-around;
    padding: 0.6rem 0;

    &-line {
      border-right: 2px dashed #5795e0;
      border-left: 2px dashed #5795e0;
    }

    &-box {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 0.15rem;

      h6 {
        color: #ff8450;
        font-size: 0.28rem;
      }

      img {
        width: 1.3rem;
        height: 1.3rem;
      }
      p {
        font-size: 0.24rem;
        line-height: 2;
      }
    }
  }
  &-vs {
    margin: 0.3rem 0;

    img {
      width: 100%;
    }
  }
}
.qyx {
  text-align: center;
  font-size: 0.28rem;
  color: #999;
  padding: 0.3rem;
}
</style>
