<template>
  <div class="container">
    <div class="newhome">
      <div class="newhome-header">
        <img src="https://c.cdn.quyixian.com/front/2023/wen.png" alt="" />
        <div class="newhome-form">
          <div class="newhome-form-title">请输入验证码</div>
          <van-form class="newhome-form-main" @submit="onSubmit">
            <van-field
              v-if="codeShow"
              type="number"
              v-model="code"
              placeholder="请输入二维码下方的身份码"
              maxlength="13"
              :rules="[{ required: true }]"
            />
            <van-field
              type="number"
              v-model="verify"
              placeholder="请输入验证码"
              minlength="4"
              :rules="[{ required: true }]"
            />
            <van-button
              block
              type="info"
              icon="https://c.cdn.quyixian.com/front/click1.png"
              native-type="submit"
              >{{ subText }}
            </van-button>
          </van-form>
        </div>
        <div class="newhome-qrcode">
          <div class="newhome-qrcode-box">
            <div class="newhome-qrcode-left">
              <img src="https://c.cdn.quyixian.com/front/ttl.jpeg" alt="" />
              <div>
                <p>5·3天天练</p>
                <p>（小学）</p>
              </div>
            </div>
            <div class="newhome-qrcode-right">
              <img src="https://c.cdn.quyixian.com/front/53tx.jpeg" alt="" />
              <div>
                <p>5·3同学</p>
                <p>（中学）</p>
              </div>
            </div>
          </div>
          <div class="newhome-qrcode-title">
            长按识别二维码关注公众号，获取更多图书增值服务
          </div>
        </div>
        <div class="newhome-prize">
          <img src="https://c.cdn.quyixian.com/front/2023/prize.png" alt="" />
        </div>
      </div>
      <h6 class="newhome-footer">北京曲一线图书策划有限公司</h6>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { Toast, Dialog } from "vant";
export default {
  name: "newhome",
  data() {
    return {
      loading: false,
      code: this.$route.query.id, //3282308232160
      codeShow: this.$route.query.id ? false : true, //是否有值，有值扫码, 无值公众号 是否显示输入框
      tid: this.$route.query.tId, //暂时无用
      verify: "", //验证码
      source: this.$route.query.source == null ? 1 : this.$route.query.source, //来源 1H5 2公众号 3手机官网 4PC官网
      bookInfo: null, //图书信息
      codeInfo: null, //码的信息
      codeType: "", //类型 1多次 2首次 3未采集或未入库出库信息 4没查到
      helpShow: false, //帮助弹出层
      subIcon: "https://c.cdn.quyixian.com/front/click1.png",
      subText: "验证",
      isWx: this.$wechat.isWeixin(), //是否再微信环境下
      province: "",
      ip: "",
    };
  },
  mounted() {
    //拿IP
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        this.ip = ip;
        //console.log(this.ip);
      });
    //是否微信环境
    if (this.isWx) {
      this.$wechat.getLocation().then((res) => {
        let params = {
          location: res.latitude + "," + res.longitude,
        };
        //console.log(res)
        this.$api.code.getWxAddres(params).then((r) => {
          let { Tag, Data } = r;
          if (Tag == 1 && Data.message == "query ok") {
            let { result } = Data;
            console.log(result);
            this.savePosition(
              position.result.address_component.province,
              position.result.address_component.city,
              position.result.address_component.district,
              position.result.address,
              position.res.latitude,
              position.res.longitude
            );
          } else {
            console.log("定位失败");
            Dialog.alert({
              title: "定位失败",
              message: "未获取到您的位置信息！请刷新",
              confirmButtonText: "刷新",
            }).then(() => {
              //刷新页面
              window.location.reload();
            });
          }
        });
      });
    } else {
      this.getLocation();
    }
  },
  methods: {
    //查询
    onSubmit(e) {
      if (!isNaN(this.verify) && this.verify.length < 4) {
        Toast("请输入正确的验证码");
        return false;
      }
      if (this.province == "") {
        Dialog.alert({
          title: "拒绝查询",
          message: "未获取到您的定位！",
          confirmButtonText: "刷新",
        }).then(() => {
          location.reload();
        });
        return false;
      }
      let params = {
        Code1: this.code,
        VerifyCode: this.verify,
      };
      this.$api.code.GetCode(qs.stringify(params)).then((res) => {
        let { Data, Tag, Message } = res;
        if (Tag == 1) {
          let { bookJson, codeJson, codeType } = Data;
          //取图书信息
          this.bookInfo = bookJson;
          //取类型
          this.codeType = codeType;
          //取码信息
          this.codeInfo = codeJson;

          let timestamp = new Date().getTime();

          this.$router.push({
            name: "Result",
            params: {
              code: this.code,
              verify: this.verify,
              source: this.source,
              bookInfo: this.bookInfo,
              codeType: this.codeType,
              codeInfo: this.codeInfo,
              ip: this.ip,
            },
            query: {
              time: timestamp,
            },
          });
        } else {
          this.$dialog.alert({
            title: "错误",
            message: Message,
          });
        }
      });
    },
    //注册腾讯获取KEY
    getLocation() {
      let geolocation = new window.qq.maps.Geolocation(
        "VBHBZ-NV46J-Y6EFK-F6A6M-255DF-JKFQZ",
        "mycode",
        [{ timeout: 10, failTipFlag: true }]
      );
      //getLocation(成功回调,错误回调)
      geolocation.getLocation(this.showPosition, this.errorPosition);
    },
    //获取位置成功
    showPosition(position) {
      console.log("定位成功");
      console.log(position);
      this.savePosition(
        position.province,
        position.city,
        position.district,
        position.addr,
        position.lat,
        position.lng
      );
    },
    //获取位置出错
    errorPosition() {
      console.log("定位失败");
      Dialog.alert({
        title: "定位失败",
        message: "未获取到您的位置信息！请刷新",
        confirmButtonText: "刷新",
      }).then(() => {
        //刷新页面
        window.location.reload();
      });
    },
    //storage保存位置信息
    savePosition(pro, city, dis, add, lat, lng) {
      this.loading = true;
      this.province = pro;
      let info = {
        province: pro,
        city: city,
        district: dis,
        address: add,
        lat: lat,
        lng: lng,
      };
      sessionStorage.setItem("addr", JSON.stringify(info));
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #48a8e7;
}
.newhome {
  background: url("https://c.cdn.quyixian.com/front/2023/bg1.png") no-repeat top
    #48a8e7;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-header {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &-form {
    margin: 0 0.3rem;
    box-shadow: 0px 0px 0.3rem 0px rgba(0, 45, 195, 0.66);
    border-radius: 0.15rem;
    display: flex;
    flex-direction: column;

    & > img {
      width: 100%;
    }

    &-title {
      width: 100%;
      height: 1.6rem;
      background: url("https://c.cdn.quyixian.com/front/2023/formhead.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 0.48rem;
      letter-spacing: 1px;
    }

    &-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background: #fff;
      padding: 0.6rem;
      border-bottom-left-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;

      .van-cell {
        border: 1px solid #ccc;
        border-radius: 0.15rem;
        margin-bottom: 0.6rem;

        &::after {
          border-bottom: 0;
        }
      }

      .van-button {
        font-size: 0.32rem;
        &--info {
          background: linear-gradient(to bottom, #f4dc4c, #ff8350);
          border: 0;
          border-radius: 0.15rem;
        }
      }
      .van-icon__image {
        font-size: 1.2em;
      }
    }
  }

  &-qrcode {
    margin: 0.3rem;
    padding: 0.3rem 0;
    border-radius: 0.15rem;
    background: #fff;
    box-shadow: 0px 0px 0.3rem 0px rgba(0, 45, 195, 0.66);

    &-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-right,
    &-left {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &-title {
      text-align: center;
      font-weight: bold;
      font-size: 0.28rem;
    }

    img {
      max-width: 1.8rem;
      width: 1.8rem;
    }
  }

  &-prize {
    margin: 0.3rem;
    border-radius: 0.15rem;
    background: #fff;
    box-shadow: 0px 0px 0.3rem 0px rgba(0, 45, 195, 0.66);

    img {
      width: 100%;
      height: 2.1rem;
    }
  }

  &-footer {
    color: #f1f1f1;
    text-align: center;
    font-weight: 500;
    font-size: 0.28rem;
    padding-top: 0.3rem;
    padding-bottom: 0.6rem;
  }
}
</style>
