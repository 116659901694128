<template>
  <div class="cont">
    <img v-for="i in imglist" :key="i" :src="i" />
  </div>
</template>

<script>
export default {
  name: "cont",
  data() {
    return {
      imglist: [
        "http://c.cdn.quyixian.com/front/cont/1.jpg",
        "http://c.cdn.quyixian.com/front/cont/2.jpg",
        "http://c.cdn.quyixian.com/front/cont/3.jpg",
        "http://c.cdn.quyixian.com/front/cont/4.jpg",
        "http://c.cdn.quyixian.com/front/cont/5.jpg",
        "http://c.cdn.quyixian.com/front/cont/6.jpg",
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0;
  overflow: hidden;
}
img {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  vertical-align: middle;
}
</style>
