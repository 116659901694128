<template>
  <div class="index32">
    <div class="index32-head">
      <div class="container">
        <van-image
          fit="container"
          width="90"
          height="90"
          src="http://c.admin.quyixian32.com/image/logo.png"
        />
        <div class="nav">
          <span>首页</span>
          <span class="code" @click="codeShow = true">正版验证</span>
        </div>
      </div>
    </div>
    <swiper
      ref="swiper"
      id="swiper"
      :options="swiperOption"
      @slideChange="onSlideChange"
    >
      <swiper-slide>
        <van-image
          fit="contain"
          src="https://c.cdn.quyixian.com/32pc/l1.jpg"
          :show-loading="false"
        />
      </swiper-slide>
      <swiper-slide>
        <van-image
          fit="contain"
          src="https://c.cdn.quyixian.com/32pc/l2.jpg"
          :show-loading="false"
        />
      </swiper-slide>
      <swiper-slide>
        <van-image
          fit="contain"
          src="https://c.cdn.quyixian.com/32pc/l3.jpg"
          :show-loading="false"
        />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <div class="index32-main">
      <van-image
        class="left"
        fit="contain"
        src="https://c.cdn.quyixian.com/32pc/z-left.jpg"
      />
      <van-image
        class="right"
        fit="contain"
        src="https://c.cdn.quyixian.com/32pc/z-right.png"
      />
    </div>
    <div class="index32-bottom">
      <van-image
        fit="contain"
        src="https://c.cdn.quyixian.com/32pc/bottom.jpg"
      />
    </div>
    <div class="index32-footer">
      <a target="_blank" href="https://beian.miit.gov.cn/"
        >晋ICP备2023016254号</a
      >
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14010502051298"
        ><img src="http://quyixian.com/Images/icon-police.png" />晋公网安备
        14010502051298号</a
      >
      <a target="_blank" href="#">山西三二金典图书有限公司</a>
      <span>加入我们</span>
      <span>联系我们</span>
    </div>

    <van-popup v-model="codeShow" closeable>
      <div class="codepopup">
        <div class="codepopup-title">图书真伪查询</div>

        <div class="codepopup-result" v-if="codeType">
          <div class="codepopup-result-book">
            <div class="left">
              <!-- Cover -->
              <van-image fit="contain" :src="bookInfo.Cover" />
            </div>
            <div class="right">
              <div class="title">
                <p>{{ bookInfo.NickName }}</p>
              </div>
              <div class="content">
                <p v-if="bookInfo.Edition">年份：{{ bookInfo.Edition }}</p>
                <p v-if="bookInfo.Banben">版本：{{ bookInfo.Banben }}</p>
                <p v-if="bookInfo.Stage">学段：{{ bookInfo.Stage }}</p>
                <p v-if="bookInfo.Folio">开本：{{ bookInfo.Folio }}</p>
                <p v-if="bookInfo.Pricing">定价：{{ bookInfo.Pricing }}</p>
                <p v-if="bookInfo.Publishing">
                  出版社：{{ bookInfo.Publishing }}
                </p>
                <p v-if="bookInfo.BookNumber">
                  书号：{{ bookInfo.BookNumber }}
                </p>
              </div>
            </div>
          </div>
          <template v-if="codeType <= '3'">
            <!-- 首次 -->
            <div
              class="codepopup-result-info"
              v-if="codeType == 1 || (codeType == 3 && codeInfo.Count == 0)"
            >
              <p class="first">此图书首次查询正版图书</p>
              <p v-if="firstadd">首次查询位置：{{ firstadd }}</p>
              <p>首次查询时间：{{ firsttime }}</p>
            </div>
            <!-- 多次 -->
            <div
              class="codepopup-result-info"
              v-if="codeType == 2 || (codeType == 3 && codeInfo.Count > 0)"
            >
              <p class="many">警告：此正版图书被查询{{ codeInfo.Count }}次</p>
              <p>首次查询位置：{{ codeInfo.FirstAddress }}</p>
              <p>首次查询时间：{{ timeFormatD(codeInfo.FirstTime) }}</p>
            </div>
          </template>
          <template v-else-if="codeType == 6">
            <div class="codepopup-result-info">
              <p class="dao">警告：您所查询的是盗版</p>
            </div>
          </template>
          <template v-else>
            <div class="codepopup-result-info">
              <p class="cidao">警告：您所查询的图书可能是盗版图书</p>
            </div>
          </template>
        </div>
        <div class="codepopup-content" v-else>
          <div class="codepopup-content-left">
            <van-image
              fit="container"
              src="https://c.cdn.quyixian.com/front/biginfo.jpg"
            />
          </div>
          <van-form @submit="onSubmit" class="codepopup-content-right">
            <div class="form-box">
              <van-field
                v-model="code"
                type="digit"
                name="code"
                :rules="[{ required: true }]"
                placeholder="请输入身份码"
              />
              <van-field
                v-model="ver"
                type="digit"
                name="ver"
                :rules="[{ required: true }]"
                placeholder="请输入验证码"
              />
            </div>
            <van-button type="primary" v-if="loading" block color="#fb8900"
              >查询中...</van-button
            >
            <van-button
              v-else
              type="primary"
              native-type="submit"
              block
              color="#fb8900"
              >查询真伪</van-button
            >
          </van-form>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import qs from "qs";
import { Toast, Dialog } from "vant";
export default {
  name: "pcindex",

  data() {
    return {
      loading: false,
      swiperOption: {
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "progressbar",
        },

        // 设置自动轮播
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        // 设置轮播可循环
        loop: true,
        speed: 500,
      },
      codeShow: false,
      code: "",
      ver: "",
      ip: "",
      province: "",
      pos: {},
      //取图书信息
      bookInfo: "",
      //取类型
      codeType: "",
      //取码信息
      codeInfo: "",
      source: 4,
      resultShow: false,
      firstadd: "",
      firsttime: this.timeFormatD(new Date()),
    };
  },
  mounted() {
    //console.log(navigator.userAgent);
    //www.quyixian32.com
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      //console.log("移动");
      //跳转移动端页面 mobile.quyixian32.com
      window.location.href = "http://mobile.quyixian32.com";
    } else {
      this.getLocation();
    }
    //拿IP
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        this.ip = ip;
        console.log(this.ip);
      });
    // window.addEventListener("resize", function () {
    //   var width = window.innerWidth;
    //   if (width < 1920 && width > 1200) {
    //   }
    // });
  },
  methods: {
    onSlideChange() {
      //console.log("swiperChange");
    },
    onSubmit() {
      if (!isNaN(this.ver) && this.ver.length < 4) {
        Toast("请输入正确的验证码");
        return false;
      }
      this.$api.code
        .GetCode(qs.stringify({ Code1: this.code, VerifyCode: this.ver }))
        .then(({ Data, Tag, Message }) => {
          if (Tag == 1) {
            console.log(Data);
            let { bookJson, codeJson, codeType } = Data;
            //取图书信息
            this.bookInfo = bookJson;
            //取类型
            this.codeType = codeType;
            //取码信息
            this.codeInfo = codeJson;
            this.resultShow = true;
            this.loading = true;

            let that = this;
            setTimeout(() => {
              that.SaveUser();
            }, 3000);
          } else {
            this.$dialog.alert({
              title: "错误",
              message: Message,
            });
          }
        });
    },
    //注册腾讯获取KEY
    getLocation() {
      let geolocation = new window.qq.maps.Geolocation(
        "SPABZ-Z2W3T-LVBX5-LTVJR-SFUMV-DXF6K",
        "mycode",
        [{ timeout: 10, failTipFlag: true }]
      );
      //getLocation(成功回调,错误回调)
      geolocation.getLocation(this.showPosition, this.errorPosition);
    },
    //获取位置成功
    showPosition(position) {
      console.log("定位成功");
      console.log(position);
      this.province = position.province;
      this.firstadd =
        position.addr == undefined && position.addr == ""
          ? position.province
          : position.addr;
      this.pos = {
        province: position.province,
        city: position.city,
        district: position.district,
        addr: position.addr,
        lat: position.lat,
        lng: position.lng,
      };
    },
    //获取位置出错
    errorPosition() {
      console.log("定位失败");
      // Dialog.alert({
      //   title: "定位失败",
      //   message: "未获取到您的位置信息！请刷新",
      //   confirmButtonText: "刷新",
      // }).then(() => {
      //   //刷新页面
      //   window.location.reload();
      // });
    },
    SaveUser() {
      let task = null,
        taskpress = null,
        book = null,
        code2 = null;
      if (this.codeInfo != null) {
        if (this.codeInfo.TaskId) {
          task = this.codeInfo.TaskId;
        }
        if (this.codeInfo.TaskId) {
          taskpress = this.codeInfo.TaskPressId;
        }
        if (this.codeInfo.BookId) {
          book = this.codeInfo.BookId;
        }
        if (this.codeInfo.Code2) {
          code2 = this.codeInfo.Code2;
        }
      }
      let info = "";

      if (this.province && this.pos.province) {
        info =
          "province:" +
          this.pos.province +
          ",city:" +
          this.pos.city +
          ",district:" +
          this.pos.district +
          ",lat:" +
          this.pos.lat +
          ",lng:" +
          this.pos.lng;
      }

      let params = {
        CodeType: this.codeType,
        Code1: this.code,
        VerifyCode: this.ver,
        TaskId: task,
        TaskPressId: taskpress,
        BookId: book,
        Code2: code2,
        Source: this.source,
        //
        IP: this.ip,
        Address: this.pos.address == undefined ? "" : this.pos.address,
        Province: this.pos.province == undefined ? "" : this.pos.province,
        City: this.pos.city == undefined ? "" : this.pos.city,
        District: this.pos.district == undefined ? "" : this.pos.district,
        Info: info,
      };
      this.$api.code.SaveUserInfo(qs.stringify(params)).then((res) => {
        let { Data, Tag, Message } = res;
        if (Tag == 1) {
          //console.log(params);
        } else {
          this.$dialog.alert({
            title: "错误",
            message: Message,
          });
        }
      });
    },
    timeFormatD(e) {
      let d = new Date(e);
      let yyyy = d.getFullYear();
      let MM = (d.getMonth() + 1).toString().padStart(2, "0");
      let dd = d.getDate().toString().padStart(2, "0");
      let h = d.getHours().toString().padStart(2, "0");
      let m = d.getMinutes().toString().padStart(2, "0");
      let s = d.getSeconds().toString().padStart(2, "0");
      return (
        yyyy + "年" + MM + "月" + dd + "日 " + h + "时" + m + "分" + s + "秒"
      );
    },
  },
};
</script>

<style lang="scss">
.index32 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .van-image {
    display: flex;
  }

  #swiper {
    max-width: 1920px;
    min-width: 1200px;
  }

  &-head {
    position: fixed;
    zoom: 1;
    top: 0;
    left: 0;
    line-height: 100px;
    height: 100px;
    padding: 0 !important;
    border: 0;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 1px #797979;
    z-index: 100;
    width: 100%;
    .container {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .nav {
        margin-left: 100px;
        color: #fff;

        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          margin-right: 100px;
          cursor: pointer;
        }
        // .code {
        //   text-decoration: underline;
        // }
      }
    }
  }
  &-main {
    max-width: 1920px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .van-image {
      width: 50%;
    }
    .left {
      margin-right: 5px;
    }
    .right {
      margin-left: 5px;
    }
  }
  &-bottom {
    max-width: 1920px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  &-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    background: #f2f2f2;
    border-top: 1px solid #d7d7d7;

    a,
    span {
      color: #333;
      margin: 50px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      img {
        margin-right: 5px;
      }
    }
  }
}

.codepopup {
  background: #f2f2f2;
  padding: 15px;

  &-title {
    text-align: center;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .van-image {
      width: 207px;
      height: 152px;
      display: flex;
    }
    &-left {
      margin-right: 15px;
    }
    &-right {
      width: 250px;
      height: 152px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &-result {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &-title {
      font-weight: 500;
      font-size: 18px;
      text-align: center;
    }

    &-book {
      width: 473px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .van-image {
        width: 155px;
      }
      .left {
        margin-right: 15px;
      }

      .right {
        .title {
          font-size: 16px;
          line-height: 1.6;
        }
        .content {
          font-size: 12px;
        }
      }
    }
    &-info {
      margin-top: 15px;
      font-size: 14px;
      width: 100%;
      text-align: left;

      .first {
        color: #40e0d0;
      }
      .many {
        color: #ffa500;
      }
      .cidao {
        color: #ff6347;
      }
      .dao {
        color: #ff0000;
      }
    }
  }
}
</style>
