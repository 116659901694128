import api from "../index";
import urls from "./url";

export default {
  //登录
  login(params) {
    return api.post(urls.login, params);
  },
  //获取码信息
  GetCode(params) {
    return api.post(urls.getCode, params);
  },
  //保存用户信息
  SaveUserInfo(params) {
    return api.post(urls.SaveInfo, params);
  },
  //获取信息
  getCodeAll(params) {
    return api.post(urls.getCodeAll, params);
  },
  //保存图片OSS
  SaveFileOSS(params) {
    return api.post(urls.SaveFile, params);
  },
  //保存盗版信息
  SaveUserPirate(params) {
    return api.post(urls.SavePirate, params);
  },
  //经纬度换取地址
  getWxAddres(params) {
    return api.get(urls.wxAddress, params);
  },
};
